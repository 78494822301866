import Vue from "vue";
import VueRouter from "vue-router";
import VueScrollTo from "vue-scrollto";
Vue.use(VueRouter);

const routes = [
     {
          path: "/ar",
          component: () => import("../layouts/default_layout.vue"),
          children: [
               {
                    path: "/ar/",
                    name: "HOME_ARABIC",
                    component: () => import("../views/ARABIC/Home.vue"),
                    meta: { arabic: true },
               },
               {
                    path: "/ar/contact",
                    name: "CONTACT_ARABIC",
                    component: () => import("../views/ARABIC/Contact.vue"),
                    meta: { arabic: true },
               },
               {
                    path: "/ar/about",
                    name: "ABOUT_ARABIC",
                    component: () => import("../views/ARABIC/About.vue"),
                    meta: { arabic: true },
               },
               {
                    path: "/ar/gallery",
                    name: "GALLERY_ARABIC",
                    component: () => import("../views/ARABIC/Gallery.vue"),
                    meta: { arabic: true },
               },

               //           // {
               //           //      path: "/rooms/seaview",
               //           //      name: "SEAVIEW",
               //           //      component: () => import("../views/Rooms/SeaView.vue"),
               //           // },
               {
                    path: "/ar/rooms/twin",
                    name: "TWIN_ARABIC",
                    component: () => import("../views/ARABIC/Rooms/Twin.vue"),
                    meta: { arabic: true },
               },
               {
                    path: "/ar/rooms/standard",
                    name: "STANDARD_ARABIC",
                    component: () => import("../views/ARABIC/Rooms/Standard.vue"),
                    meta: { arabic: true },
               },

               {
                    path: "/ar/rooms/king",
                    name: "KING_ARABIC",
                    component: () => import("../views/ARABIC/Rooms/King.vue"),
                    meta: { arabic: true },
               },

               {
                    path: "/ar/staples",
                    name: "STAPLES_ARABIC",
                    component: () => import("../views/ARABIC/Staples.vue"),
                    meta: { arabic: true },
               },
               {
                    path: "/ar/halls",
                    name: "HALLS_ARABIC",
                    component: () => import("../views/ARABIC/Halls.vue"),
                    meta: { arabic: true },
               },
               {
                    path: "/ar/wellness",
                    name: "WELLNESS_ARABIC",
                    component: () => import("../views/ARABIC/Wellness.vue"),
                    meta: { arabic: true },
               },

               {
                    path: "*",
                    name: "NOTFOUND_ARABIC",
                    component: () => import("../views/ARABIC/404.vue"),
                    meta: { arabic: true },
               },
          ],
     },

     {
          path: "/",
          component: () => import("../layouts/default_layout.vue"),
          children: [
               {
                    path: "/",
                    name: "HOME",
                    component: () => import("../views/Home.vue"),
                    meta: { arabic: false },
               },
               {
                    path: "/contact",
                    name: "CONTACT",
                    component: () => import("../views/Contact.vue"),
                    meta: { arabic: false },
               },
               {
                    path: "/about",
                    name: "ABOUT",
                    component: () => import("../views/About.vue"),
                    meta: { arabic: false },
               },
               {
                    path: "/gallery",
                    name: "GALLERY",
                    component: () => import("../views/Gallery.vue"),
                    meta: { arabic: false },
               },

               // {
               //      path: "/rooms/seaview",
               //      name: "SEAVIEW",
               //      component: () => import("../views/Rooms/SeaView.vue"),
               // },
               {
                    path: "/rooms/twin",
                    name: "TWIN",
                    component: () => import("../views/Rooms/Twin.vue"),
                    meta: { arabic: false },
               },
               {
                    path: "/rooms/standard",
                    name: "STANDARD",
                    component: () => import("../views/Rooms/Standard.vue"),
                    meta: { arabic: false },
               },

               {
                    path: "/rooms/king",
                    name: "KING",
                    component: () => import("../views/Rooms/King.vue"),
                    meta: { arabic: false },
               },

               {
                    path: "/staples",
                    name: "STAPLES",
                    component: () => import("../views/Staples.vue"),
                    meta: { arabic: false },
               },
               {
                    path: "/halls",
                    name: "HALLS",
                    component: () => import("../views/Halls.vue"),
                    meta: { arabic: false },
               },
               {
                    path: "/wellness",
                    name: "WELLNESS",
                    component: () => import("../views/Wellness.vue"),
                    meta: { arabic: false },
               },

               {
                    path: "*",
                    name: "NOTFOUND",
                    component: () => import("../views/404.vue"),
                    meta: { arabic: false },
               },
          ],
     },
];

const router = new VueRouter({
     mode: "history",
     base: process.env.BASE_URL,
     routes,
     scrollBehavior(to, from, savedPosition) {
          if (to.params.scroll) {
               // return {
               //      selector: to.params.scroll,
               // };
               return savedPosition;
          }
          if (savedPosition) {
               return savedPosition;
          } else {
               return { x: 0, y: 0 };
          }
     },
});

router.beforeEach((to, from, next) => {
     let arabic = localStorage.getItem("isArabic");

     if (arabic == "true" && !to.meta.arabic) {
          next({ name: to.name + "_ARABIC" });
     } else {
          next();
     }
});

export default router;
