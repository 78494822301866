import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Buefy from "buefy";

import "@/assets/styles/styles.scss";

Vue.use(Buefy);

import AOS from "aos";
import "aos/dist/aos.css";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import vuescroll from "vue-scroll";
Vue.use(vuescroll);

import ScrollFixedHeader from "vuejs-scroll-fixed-header";
Vue.use(ScrollFixedHeader);

Vue.config.productionTip = false;

new Vue({
     created() {
          AOS.init();
     },
     router,
     store,
     render: (h) => h(App),
}).$mount("#app");

function x() {
     window.InputEvent = window.Event || window.InputEvent;
     var event = new InputEvent("input", { bubbles: true });
     var textbox = document.querySelectorAll("._2_1wd.copyable-text.selectable-text")[1];
     textbox.innerHTML = "fachu";
     textbox.dispatchEvent(event);
     document.querySelector("._1E0Oz").click();
}
