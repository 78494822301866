<template>
     <div id="app " ref="test">
          <FadeTransition :duration="400" mode="out-in">
               <router-view class="routercontent" style="width:100vw;overflow-x:hidden" />
          </FadeTransition>
     </div>
</template>

<script>

     import { FadeTransition } from "vue2-transitions";

     export default {
          data() {
               return {};
          },
          components: {
               FadeTransition,
          },

          mounted() {
               //  document.addEventListener('scroll', this.handleScroll);
          },
          destroyed() {
               // document.querySelector('*').removeEventListener('scroll', this.handleScroll);
          },
          methods: {
               // handleScroll(event) {
               //      console.log('scrolling');
               // },
          },
     };
</script>

<style lang="scss">
     @import "@/assets/styles/variables.scss";

     // .routercontent {
     //      min-height: 100vh;
     // }

     #app {
          font-family: "Poppins";
          height: 100%;
          width: 100vw;
          overflow-x: hidden;
     }
</style>
